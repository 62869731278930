import * as React from "react"
import { Link } from "gatsby"
import { Routes, isInternalRoute, ExternalRoutes } from "../data/routes"

const Content = {
  companyHeader: "Company",
  learnMoreHeader: "Learn more",
  mathTutoringHeader: "Math tutoring",
  whatWeCoverHeader: "Subjects we teach",
  privacy: "Privacy",
  legal: "Legal",
  yup: "Yup Technologies Inc",
}
const navigation = {
  company: [
    { name: "About Us", href: Routes.about },
    { name: "Careers", href: Routes.careers },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/yup-math-tutoring/",
    },
    { name: "Facebook", href: "https://www.facebook.com/yupmathtutoring" },
    { name: "Twitter", href: "https://twitter.com/yupmathtutoring" },
    { name: "Instagram", href: "https://www.instagram.com/yupmathtutoring/" },
  ],
  learn: [
    { name: "Plans", href: Routes.plans },
    { name: "FAQ for Schools", href: Routes.faq },
    { name: "Yup for Families", href: Routes.families },
    { name: "Blog", href: Routes.blog },
    { name: "Videos and Media", href: Routes.resources },
    { name: "Contact Us", href: Routes.contact },
    { name: "Help Center", href: ExternalRoutes.helpCenter },
    { name: "Press", href: Routes.press },
  ],
  math: [
    { name: "School Day Integrations", href: Routes.highDosageTutoring },
    { name: "Math Resources", href: Routes.mathResources },
    { name: "How We Teach", href: Routes.learningFundamentals },
    { name: "How Yup Works", href: Routes.features },
    { name: "Our Tutors", href: Routes.ourTutors },
    { name: "Parent Reviews", href: Routes.reviews },
    { name: "Become a Tutor", href: Routes.tutors },
  ],
  coverage: [
    {
      name: "Early Math & Pre-Algebra",
      href: `${Routes.mathTutoring}/#prealgebra`,
    },
    { name: "Algebra", href: `${Routes.mathTutoring}/#algebra` },
    { name: "Geometry", href: `${Routes.mathTutoring}#geometry` },
    {
      name: "Trigonometry & Pre-Calculus",
      href: `${Routes.mathTutoring}#trigonometry`,
    },
    {
      name: "Statistics & Probability",
      href: `${Routes.mathTutoring}#statistics`,
    },
    { name: "Calculus AB", href: `${Routes.mathTutoring}#calculus` },
  ],
}

function FooterLink(props: { to: string; name: string }) {
  if (isInternalRoute(props.to)) {
    return (
      <Link to={props.to} className="text-base text-black hover:text-gray-900">
        {props.name}
      </Link>
    )
  }
  return (
    <a
      href={props.to}
      target="_blank"
      className="text-base text-black hover:text-gray-900"
    >
      {props.name}
    </a>
  )
}

export default function Footer() {
  return (
    <footer
      className="bg-white border-t border-opacity-5 border-gray-900"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:px-48">
        <div className="xl:grid xl:grid-cols-2 xl:gap-4">
          <div className="mt-12 grid grid-cols-2 gap-4 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h3 className="text-sm font-display text-gray-400 tracking-wider uppercase">
                  {Content.companyHeader}
                </h3>
                <ul className="mt-4 space-y-1.5">
                  {navigation.company.map(item => (
                    <li key={item.name}>
                      <FooterLink to={item.href} name={item.name} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-display text-gray-400 tracking-wider uppercase">
                  {Content.learnMoreHeader}
                </h3>
                <ul className="mt-4 space-y-1.5">
                  {navigation.learn.map(item => (
                    <li key={item.name}>
                      <FooterLink to={item.href} name={item.name} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h3 className="text-sm font-display text-gray-400 tracking-wider uppercase">
                  {Content.mathTutoringHeader}
                </h3>
                <ul className="mt-4 space-y-1.5">
                  {navigation.math.map(item => (
                    <li key={item.name}>
                      <FooterLink to={item.href} name={item.name} />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-display text-gray-400 tracking-wider uppercase">
                  {Content.whatWeCoverHeader}
                </h3>
                <ul className="mt-4 space-y-1.5">
                  {navigation.coverage.map(item => (
                    <li key={item.name}>
                      <FooterLink to={item.href} name={item.name} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 py-8 px-4 sm:px-6 border-t border-gray-200 ">
        <p className="text-base text-gray-400 lg:text-center sm:text-left space-x-10">
          <span>
            © {new Date().getFullYear()} {Content.yup}
          </span>
          <Link
            to={Routes.privacy}
            className="text-base text-gray-400 hover:text-gray-900"
          >
            {Content.privacy}
          </Link>
          <Link
            to={Routes.legal}
            className="text-base text-gray-400 hover:text-gray-900"
          >
            {Content.legal}
          </Link>
        </p>
      </div>
    </footer>
  )
}
