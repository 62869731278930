import React, { useEffect } from "react"
import { InlineWidget } from "react-calendly"
import { Videos } from "../../data/assets"
import { Routes } from "../../data/routes"
import Button from "../../components/button"
import Card from "../../components/card"
import Img from "../../components/img"
import Seo from "../../components/seo"
import SocialProof from "../../components/SocialProof"
import Video from "../../components/Video"
import MainBanner from "../../components/MainBanner"
import Footer from "../../components/footer"
import { track, Category, Action } from "../../helpers/Analytics"
import { Carousel } from "react-responsive-carousel"
import HowItWorks from "../../components/HowItWorks"
import { SimpleCTA } from "../../components/CTA"

const Content = {
  pageName: "Yup Math Tutoring",
  description: `Yup is your math teacher multiplier. We provide secure access to
    expert math tutors 24/7, powering High-Dosage Tutoring in and out of the
    classroom. Our "Math Learning Support System" gives teachers extra capacity,
    actionable data, and is like having a teaching assistant in every classroom.`,
  headerCTAText: "Schedule a demo",

  separator: "demo-separator.svg",
  separatorDark: "demo-separator-dark.svg",
  separatorLight: "demo-separator-light.svg",

  // Stats section
  stats: [
    {
      stat: ">25k",
      image: "icon-students.svg",
      imagealttext: "Group of students",
      description: "School students served",
    },
    {
      stat: ">300k",
      image: "icon-laptop.svg",
      imagealttext: "Person typing on laptop",
      description: "Tutoring sessions",
    },
    {
      stat: ">160k",
      image: "icon-clock.svg",
      imagealttext: "Clock face",
      description: "Teacher hours saved",
    },
  ],

  // Demo section
  demoHeader: "Let's connect!",
  demoDescription: `We can tell you more (and answer any questions you have) on a Zoom call.
    If you'd like, we can also give you a live demo. Just book a time below!`,
  demoClose: "There's no preparation required on your part. Just pick a time!",
  demoLink:
    "https://calendly.com/christianyup/yup-math-demo?hide_event_type_details=1&hide_gdpr_banner=1",
  demoAlternative:
    "Too busy to schedule a time? No problem! We'd love to chat. Please email ",
  demoAlternativeLinkText: "christian@yup.com",
  demoAlternativeLink: "mailto:christian@yup.com?subject=Interested in a Demo",

  // Videos section
  videosHeader: "See Yup in action!",
  values: [
    {
      video: Videos.yupDemo,
      description: `The best way to get a better sense of what we offer is to see it
        in action. Here's a 3 minute video (1.5 minutes if you watch at double-speed!)
        showing a student and tutor interacting:`,
    },
  ],

  // Challenge Section
  challengesHeader:
    "Many schools around the country are facing these challenges",
  challenges: [
    {
      header: "Staffing",
      description: `"We're down a math teacher and it's hard to find a great replacement"`,
    },
    {
      header: "Fatigue",
      description: `"Our teachers are tired"`,
    },
    {
      header: "Capacity",
      description: `"Teachers are stretched very thin"`,
    },
    {
      header: "Learning Loss",
      description: `"Students have suffered learning loss during the past year"`,
    },
    {
      header: "New Students",
      description: `"Our 9th graders are new to the school district and aren't ready"`,
    },
    {
      header: "Remote Learning",
      description: `"We need to support students who were (or still are) learning remotely"`,
    },
  ],
  challengesCrowd: "demo-crowd.svg",
  challengesTeacher: "demo-teacher.svg",
  challengesBlackboard: "demo-blackboard.svg",
  challengesEmphasis: `The end result is <span class="text-primary">tired teachers</span>
    who are <span class="text-primary">stretched thin</span> trying their
    best, and students who still have <span class="text-primary">significant
    learning gaps</span>.`,

  // Problem Section
  problemHeader: "But it's hard to find good math teachers",
  problemText: `Hiring additional teachers is a challenge, both because of budgets and
    just finding great teachers to fill open roles.`,
  problemEmphasis: `With all these constraints, how can school leaders ensure
    that learning gaps are closed, and that
    <span class="text-primary">students are set up to succeed with math?</span>`,
  problemImage: "demo-hiring.svg",

  // Solution Section
  solutionSubheader: "Yup is here to support with",
  solutionHeader: `24/7 Math Tutoring`,
  solutionText: `We provide on-demand math tutoring, available 24 hours a day, 7 days a
    week. Students can get help whenever they’re studying at home, or are stuck on their
    math homework.`,

  // How it works
  howItWorksHeader: "How does Yup work?",
  howItWorksText: `Your students don’t need to book ahead of time: whenever they're stuck,
    they open the Yup app (on their smartphone, tablet, laptop or Chromebook) and snap a
    photo of where they’re stuck. In a minute or two, they’ll be connected one-on-one with
    an expert math tutor who is experienced in working with middle and high school students.`,

  // Safe section
  safeHeader: "Bring safe, worry-free tutoring to your students",
  safePoints: [
    {
      title: "Reduce the demand on your teachers",
      image: "clipboard-lady.svg",
      description: `Students are able to access support anytime, allowing teachers to focus
        their time and effort where it matters most. Also, the message and whiteboard history
        is saved, so the student (and their classroom teacher) can review any tutoring
        session in the future.`,
    },
    {
      title: "Offer safe tutoring to your students",
      image: "icon-session.svg",
      description: `All interactions between students and tutors are via messages, images and a
        virtual whiteboard. We've seen that some students who are shy about asking for help
        in person are more comfortable with this, because they don't need to show their face
        like they would on a video call.`,
    },
    {
      title: "Increase your students' engagement",
      image: "man-with-books-on-head.svg",
      description: `Often, students who are behind are 'checked out' during regular math class, because they
        can't follow the material. With Yup's tutors helping students catch up, these kids start
        to become more engaged with the grade-level content being covered in the classroom.`,
    },
  ],

  // Concerns section
  concernHeader: "But will it work for your school?",
  concerns: [
    {
      title: "Will it work with my curriculum?",
      image: "icon-help-center.svg",
      description: `We do all the work to set up access, and provide teachers with materials
        they can use to introduce Yup to their students. Our tutors work with students on whatever
        topic they need, and don't require the student to use any specific curriculum or book.`,
    },
    {
      title: "Can remote tutors really help?",
      image: "icon-boy-phone.svg",
      description: `Each time a student connects with a tutor, they start by showing what they're
        working on. This means the tutor can meet the student where they're at, without any
        preconceived notion of where the student 'should' be based on their grade. This
        eliminates any chance of students being embarrassed when they're asking for help.`,
    },
    {
      title: "Do we have the budget?",
      image: "icon-money.svg",
      description: `Getting Yup is straightforward. Many schools of all types (public, private,
        charter, and even online schools) have found Yup fits within their budget, and is easy
        to introduce. Yup not only costs less than a math teacher, but even less than a teaching
        assistant. And that's for 24/7 access for all students.`,
    },
  ],

  // Testimonials
  testimonials: [
    {
      text: `We see students use Yup more than 20 times, which demonstrates to
        me that they are using this as a critical resource. I feel confident that some
        students passed their math classes because they had Yup.`,
      name: "Catherine Mencher",
      job: "Academic Affairs Director, College Track",
    },
    {
      text: `Yup has been a great resource for our students, especially in this time of
        distance-learning. The 24/7 access to a math tutor has helped reduce student
        frustration and ultimately helped them feel more successful in class...
        After a successful roll-out at our middle schools, we are happy to be
        extending our Yup services to our elementary school students.`,
      name: "Ayindé Rudolph Ed.D.",
      job: "Superintendent, Mountain View Whisman School District",
    },
    {
      name: "Director of Academic Support",
      job: "Westminster",
      text: `Yup has served as an extremely valuable resource during the pandemic.
        The 24/7 access to online tutors has helped reach our students who are learning
        virtually. The best part about Yup is that it is focused on learning. They
        do not give out answers, but instead focus on the long-term learning of math
        concepts. Their explanations are always thorough and precise!`,
    },
    {
      name: "Drew Karimlou",
      job: "Instructional Coach, Synergy Academies",
      text: `Our school started using Yup this year and we were really impressed.
        Students who don't typically have access to tutoring feel empowered to learn on
        their own with Yup! Any school that is looking for a personalized approach to
        math instruction should be looking at Yup.`,
    },
    {
      name: "Teacher",
      job: "Common Ground High School",
      text: `Because students can submit a problem directly from what I assigned,
        Yup tutors were able to provide support that directly related to what we
        were doing in the classroom. Pair that with the excellent tutoring and I was
        thrilled with our classroom engagement & student results.`,
    },
  ],
  // Closing CTA
  closingCTA: "Learn what it means to bring Yup to your school or district",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule a Demo",
    },
  ],
}

function StatsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/stats-sections#component-da2f699b57da2cabac8c760a8d34f6a6
  return (
    <div className="max-w-5xl mx-auto p-4 pt-8">
      <dl className="text-center sm:grid sm:grid-cols-3 sm:gap-8">
        {Content.stats.map(stat => (
          <div className="flex flex-col py-2 mt-2" key={stat.description}>
            <dt>
              <Img
                src={stat.image}
                alt={stat.imagealttext}
                className="mx-auto sm:mb-2 h-16 sm:h-28 w-auto"
              />
            </dt>
            <dt className="order-2 sm:mt-2 sm:text-lg leading-6 font-medium text-gray-100">
              {stat.description}
            </dt>
            <dd className="order-1 text-3xl sm:text-4xl font-extrabold text-white">
              {stat.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

function Separator(props: { theme?: "light" | "dark"; className?: string }) {
  let image = Content.separator
  if (props.theme === "light") {
    image = Content.separatorLight
  } else if (props.theme === "dark") {
    image = Content.separatorDark
  }
  return (
    <div className={`flex justify-center h-48 md:h-96 ${props.className}`}>
      <Img src={image} alt="" className="h-full" />
    </div>
  )
}

function Emphasis(props: { text: string; className?: string }) {
  return (
    <p
      className={`my-8 px-4 md:my-16 max-w-4xl mx-auto text-2xl md:text-3xl text-center font-extrabold ${props.className}`}
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  )
}

function ChallengesSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div className="pb-8 px-4 max-w-7xl mx-auto">
      <p className="pt-16 max-w-3xl mx-auto text-center  mb-4 text-xl sm:text-3xl leading-8 font-extrabold tracking-tight text-gray-200">
        {Content.challengesHeader}
      </p>
      <div className="mx-auto flex justify-center items-end mt-8">
        <Img
          src={Content.challengesTeacher}
          alt="teacher"
          className="h-32 flex-shrink -mx-10"
        />
        <Img
          src={Content.challengesBlackboard}
          alt="blackboard"
          className="h-40 max-w-full"
        />
      </div>
      <Carousel
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={10000}
        showThumbs={false}
        width="100vw"
      >
        {Content.challenges.map(item => (
          <div
            className="max-w-7xl mx-auto text-center sm:pb-8 px-4"
            key={item.header}
          >
            <p className="text-5xl mt-8 font-extrabold text-primary sm:text-6xl sm:tracking-tight lg:text-7xl">
              {item.header}
            </p>
            <p className="my-4 pb-4 mx-auto text-xl sm:text-3xl text-white">
              {item.description}
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

function ProblemSection() {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div className="py-8 px-4 max-w-7xl mx-auto md:py-16">
      <div className="md:grid md:grid-cols-3 items-center">
        <div>
          <Img
            src={Content.problemImage}
            alt="hiring"
            className="h-full -my-12"
          />
        </div>
        <div className="col-span-2 pt-8 px-4 md:px-8">
          <p className="sm:mt-16 text-4xl text-center md:text-left font-extrabold sm:text-5xl sm:tracking-tight text-white">
            {Content.problemHeader}
          </p>
          <p className="my-8 text-lg sm:text-2xl text-center md:text-left text-gray-100">
            {Content.problemText}
          </p>
        </div>
      </div>
    </div>
  )
}

function SolutionSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-25ce2a81d17545994655ccc4062651a4
  return (
    <div className="max-w-7xl mx-auto">
      <p className="mt-16 text-2xl max-w-prose mx-auto text-center font-extrabold sm:text-4xl sm:tracking-tight text-white">
        {Content.solutionSubheader}
      </p>
      <p className="mt-4 text-5xl max-w-prose mx-auto text-center font-extrabold sm:text-7xl sm:tracking-tight text-white">
        {Content.solutionHeader}
      </p>
      <p className="mt-8 text-xl max-w-prose mx-auto sm:text-2xl text-center text-white">
        {Content.solutionText}
      </p>
      <div className="pt-8 flex flex-col sm:flex-row justify-center">
        <Button href={Routes.schools} text={Content.headerCTAText} />
      </div>
    </div>
  )
}

function HowItWorksSection() {
  return (
    <Card>
      <p className="mt-2 mb-8 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
        {Content.howItWorksHeader}
      </p>
      <p className="max-w-5xl mx-auto text-lg text-center text-gray-500 mb-8">
        {Content.howItWorksText}
      </p>
      <HowItWorks />
    </Card>
  )
}

function SafeSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <p className="mt-2 mb-8 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
        {Content.safeHeader}
      </p>
      {Content.safePoints.map((section, index) => (
        <div
          className={`flex flex-col items-center py-8 ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          }`}
          key={section.description}
        >
          <div className="w-full flex-grow md:w-1/3">
            <Img
              src={section.image}
              alt={section.title}
              className="w-full max-h-56 max-w-xs object-fit mx-auto"
            />
          </div>
          <div className="text-center md:text-left w-full md:w-2/3">
            <p className="mt-2 md:px-10 text-gray-900 text-2xl font-extrabold tracking-tight">
              {section.title}
            </p>
            <div className="md:ml-auto md:px-10">
              {section.description.split(/\n\n/g).map(description => (
                <p
                  className="my-3 text-lg text-gray-500"
                  key={`text-${description}`}
                >
                  {description}
                </p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

function ConcernsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <p className="mt-2 mb-8 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
        {Content.concernHeader}
      </p>
      {Content.concerns.map((section, index) => (
        <div
          className={`flex flex-col items-center py-8 ${
            index % 2 ? "md:flex-row" : "md:flex-row-reverse"
          }`}
          key={section.description}
        >
          <div className="w-full flex-grow md:w-1/3">
            <Img
              src={section.image}
              alt={section.title}
              className="w-full max-h-56 max-w-xs object-fit mx-auto"
            />
          </div>
          <div className="text-center md:text-left w-full md:w-2/3">
            <p className="mt-2 md:px-10 text-gray-900 text-2xl font-extrabold tracking-tight">
              "{section.title}"
            </p>
            <div className="md:ml-auto md:px-10">
              {section.description.split(/\n\n/g).map(description => (
                <p
                  className="my-3 text-lg text-gray-500"
                  key={`text-${description}`}
                >
                  {description}
                </p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </Card>
  )
}

function TestimonialsSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/testimonials#component-fd7b8bd425f42f6504b22e1ecc6b43c9
  return (
    <div className="bg-gray-700">
      <Carousel
        showStatus={false}
        showArrows={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={10000}
        showThumbs={false}
        width="100vw"
      >
        {Content.testimonials.map(quote => (
          <section key={quote.name} className="overflow-hidden h-full">
            <blockquote className="max-w-7xl mx-auto py-12 pb-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-white">
                <p>&ldquo;{quote.text}&rdquo;</p>
              </div>
              <footer className="mt-8">
                <div className="md:flex md:items-center md:justify-center">
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div className="text-base font-medium text-white">
                      {quote.name}
                    </div>

                    <svg
                      className="hidden md:block mx-1 h-5 w-5 text-primary"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M11 0h3L9 20H6l5-20z" />
                    </svg>

                    <div className="text-base font-medium text-gray-100">
                      {quote.job}
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </section>
        ))}
      </Carousel>
    </div>
  )
}

function DemoSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  React.useEffect(() => {
    window.addEventListener("message", e => {
      if (e.data.event === "calendly.event_scheduled") {
        track(Category.demoPage, Action.scheduleDemo)
      }
    })
  }, [])

  function trackEmailClick() {
    track(Category.demoPage, Action.clickedDemoEmail)
  }

  return (
    <div id="calendly">
      <div className="bg-secondary pt-8">
        <div className="max-w-7xl mx-auto flex flex-col items-center">
          <p className="mt-2 text-white text-5xl text-center font-extrabold sm:text-6xl">
            {Content.demoHeader}
          </p>
          <p className="mt-3 text-center max-w-4xl text-lg sm:text-2xl text-white">
            {Content.demoDescription}
          </p>
          <div
            className="w-full px-0 py-4 sm:px-4 flex-grow md:w-1/2"
            id="calendly"
          >
            <Card className="p-0">
              <div className="overflow-x-auto -m-2">
                <InlineWidget url={Content.demoLink} />
              </div>
            </Card>
          </div>
          <p className="sm:text-lg text-white">
            {Content.demoAlternative}
            <a
              href={Content.demoAlternativeLink}
              className="text-primary"
              target="_blank"
              onClick={trackEmailClick}
            >
              {Content.demoAlternativeLinkText}
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

function VideoSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  function onPlay() {
    track(Category.demoPage, Action.playedVideo)
  }
  return (
    <Card>
      <p className="mt-2 mb-8 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl text-center">
        {Content.videosHeader}
      </p>
      {Content.values.map((section, index) => (
        <div
          className={`flex flex-col items-center`}
          key={`demo-section-${index}`}
        >
          {section.description.split(/\n\n/g).map((description, i) => (
            <p
              className="max-w-3xl mx-auto text-lg text-center text-gray-500"
              key={`demo-section-${index}-${i}`}
            >
              {description}
            </p>
          ))}
          <div className="w-full max-w-prose p-4 flex-grow">
            <Video
              url={section.video.url}
              image={section.video.cover}
              onPlay={onPlay}
            />
          </div>
        </div>
      ))}
    </Card>
  )
}

let timer11S: NodeJS.Timeout
let timer30S: NodeJS.Timeout
const Demo = () => {
  useEffect(() => {
    track(Category.demoPage, Action.visitedDemoPage)
    timer11S = setTimeout(() => {
      track(Category.demoPage, Action.stayedOnPage11s)
    }, 11000)
    timer30S = setTimeout(() => {
      track(Category.demoPage, Action.stayedOnPage30s)
    }, 30000)

    return () => {
      clearTimeout(timer11S)
      clearTimeout(timer30S)
    }
  }, [])

  return (
    <main className="bg-background overflow-hidden">
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.home}
      />

      {/* Problem */}
      <div className="bg-gray-700 pb-16">
        <ChallengesSection />
        <Separator />
        <Emphasis text={Content.challengesEmphasis} className="text-white" />
        <Separator />
        <ProblemSection />
        <Separator />
        <Emphasis text={Content.problemEmphasis} className="text-white" />
      </div>
      <div className="bg-gradient-to-b from-gray-700 to-secondary h-16" />

      {/* Solution */}
      <div className="bg-secondary py-16">
        <Separator theme="light" className="-mt-40" />
        <SolutionSection />
        <StatsSection />
      </div>
      <div className="bg-gradient-to-b from-secondary to-background h-16" />

      {/* Info */}
      <Separator theme="dark" className="-mt-32" />
      <HowItWorksSection />
      <Separator theme="dark" />
      <VideoSection />
      <Separator theme="dark" />
      <SafeSection />
      <Separator theme="dark" />
      <ConcernsSection />
      <Separator theme="dark" className="-mb-24" />

      {/* Demo sign up */}
      <div className="bg-gradient-to-b from-background to-secondary h-16" />
      <div className="bg-secondary py-8 md:py-16">
        <SimpleCTA
          title={Content.closingCTA}
          actions={Content.closingCTAActions}
        />
        <MainBanner />
      </div>
      <div className="bg-gradient-to-b from-secondary to-gray-700 h-16" />

      {/* Proof */}
      <TestimonialsSection />
      <SocialProof condensed={true} />
      <Footer />
    </main>
  )
}

export default Demo
