import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

let allImages: any

function useImageQuery() {
  if (!allImages) {
    allImages = useStaticQuery(
      graphql`
        query {
          images: allFile(
            filter: {
              ext: { in: [".svg", ".png", ".jpeg", ".jpg"] }
              relativeDirectory: { eq: "" }
            }
          ) {
            edges {
              node {
                relativePath
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      `
    )
  }
  return allImages.images.edges
}

type ImageProps = {
  src: string
  alt?: string
  className?: string
  cover?: boolean
}

export default function Image(props: ImageProps) {
  const images = useImageQuery()

  const classes = []
  if (props.className) {
    classes.push(props.className)
  }

  const image = images.find((n: any) => {
    return n.node.relativePath === props.src
  })

  if (!image) {
    return null
  }

  // Handle svgs
  if (!image.node.childImageSharp && props.src.endsWith(".svg")) {
    classes.push("object-contain")
    return (
      <img
        src={image.node.publicURL}
        alt={props.alt ?? ""}
        className={classes.join(" ")}
      />
    )
  }

  const src = getImage(image.node)
  if (!src) {
    return null
  }

  return (
    <GatsbyImage
      alt={props.alt ?? ""}
      image={src}
      className={classes.join(" ")}
      objectFit={props.cover ? "cover" : "contain"}
    />
  )
}
