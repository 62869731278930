import React from "react"
import { Link } from "gatsby"
import { Routes } from "../data/routes"

const Content = {
  text: `Yup math tutoring is an allowable use of stimulus funds.`,
  text2: `Invest in math tutoring to support student remediation and acceleration.`,
  linkText: "Learn how",
  link: Routes.schools,
}

type MainBannerProps = {
  mobile?: boolean
}

export default function MainBanner(props: MainBannerProps) {
  // Original Tailwind UI component: https://tailwindui.com/components/marketing/elements/banners#component-1a63aa510db7761c581486c7259487e8
  return (
    <div
      className={`bg-secondary text-white ${
        props.mobile ? "lg:hidden" : "hidden lg:block"
      }`}
    >
      <div className="max-w-7xl mx-auto p-3">
        <p className="ml-3 font-medium text-center">
          {Content.text}&nbsp;
          <span className="hidden sm:inline">{Content.text2}</span>&nbsp;
          <Link className="text-primary" to={Content.link}>
            {Content.linkText}
          </Link>
        </p>
      </div>
    </div>
  )
}
