import React from "react"

import { isInternalRoute } from "../data/routes"

type ButtonProps = {
  text: string
  href: string
  className?: string
  onClick?: () => void
}

function getTarget(href: string) {
  return isInternalRoute(href) ? "" : "_blank"
}

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <a
      href={props.href}
      className={`inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-transparent text-gray-900 bg-primary-500 hover:bg-primary-600 ${
        props.className ?? ""
      }`}
      target={getTarget(props.href)}
      onClick={props.onClick}
    >
      {props.text}
    </a>
  )
}

export const PrimaryOutlineButton = (props: ButtonProps) => {
  return (
    <a
      href={props.href}
      className={`inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-2 border-primary-500 text-primary-500 hover:border-primary-600 ${
        props.className ?? ""
      }`}
      target={getTarget(props.href)}
      onClick={props.onClick}
    >
      {props.text}
    </a>
  )
}

export const SecondaryButton = (props: ButtonProps) => {
  return (
    <a
      href={props.href}
      className={`inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-transparent bg-secondary-500 text-white hover:bg-secondary-600 ${
        props.className ?? ""
      }`}
      target={getTarget(props.href)}
      onClick={props.onClick}
    >
      {props.text}
    </a>
  )
}

export const SecondaryOutlineButton = (props: ButtonProps) => {
  return (
    <a
      href={props.href}
      className={`inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-2 border-secondary-500 text-secondary-500 hover:border-secondary-600 hover:text-secondary-600 ${
        props.className ?? ""
      }`}
      target={getTarget(props.href)}
      onClick={props.onClick}
    >
      {props.text}
    </a>
  )
}

export const SubmitButton = (props: { text: string; className?: string }) => {
  return (
    <button
      type="submit"
      className={`inline-flex justify-center px-5 py-2 rounded-full shadow-sm font-display uppercase text-base text-center hover:shadow-md focus:outline-none m-2 border-transparent text-gray-900 bg-primary-500 hover:bg-primary-600 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 ${
        props.className ?? ""
      }`}
    >
      {props.text}
    </button>
  )
}

export default PrimaryButton
