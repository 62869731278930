import React from "react"
import Img from "./img"

const Content = {
  header: "Backed by the biggest names in education",
  investors: [
    {
      name: "Stanford",
      logo: "logo-stanford.png",
    },
    {
      name: "Sesame Street",
      logo: "logo-sesame-street.png",
    },
    {
      name: "Collaborative Fund",
      logo: "logo-collaborative-fund.png",
    },
  ],
}

export default function SocialProof(props: { condensed: boolean }) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/logo-clouds#component-6b864c393af88d7b8a2ac53eaebf6403
  return (
    <div className="bg-gray-700">
      <div
        className={`max-w-7xl mx-auto ${
          props.condensed ? "py-4 lg:py-0" : "py-12"
        } px-4 sm:px-6 lg:px-8`}
      >
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <h2
            className={`max-w-md mx-auto ${
              props.condensed ? "text-2xl" : "text-3xl"
            } font-extrabold text-white text-center lg:max-w-xl lg:text-left`}
          >
            {Content.header}
          </h2>
          <div className="flow-root self-center mt-4 lg:mt-0">
            <div className="flex flex-wrap justify-evenly">
              {Content.investors.map(investor => (
                <div
                  key={investor.name}
                  className="m-4 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-1 w-1/4"
                >
                  <Img
                    className="h-16 w-full"
                    src={investor.logo}
                    alt={investor.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
